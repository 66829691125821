import React from "react";
import styled from "styled-components";
import Constants from "../../Constants";

const BaseSection = styled.section`
  background-color: ${Constants.themeColor};

  & > .content {
    max-width: ${Constants.maxWidth}px;
    margin: 0 auto;
    padding: ${Constants.basePadding}px;
    display: flex;
    flex-direction: row;

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
    }
  }

  @media (max-width: 720px) {
    & .content {
      flex-direction: column;
    }
  }
`;

export default BaseSection;
