import React from 'react';
import styled from 'styled-components';
import { useOutlet } from 'reconnect.js';
import BaseSection from '../BaseSection';
import nft from '../../Utils/Nft';
import * as AppActions from '../../AppActions';
import * as ErrorHandler from '../Error';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function Navbar(props) {
  const { hideButtons = false } = props;
  const [updateCnt, setUpdateCnt] = useOutlet('update-counter');

  const forceUpdate = React.useCallback(async () => {
    setUpdateCnt((cnt) => cnt + 1);
  }, [setUpdateCnt]);

  async function onConnectClick() {
    if (ErrorHandler.handleGlobalError()) {
      return;
    }

    AppActions.setLoading(true);
    await delay(500);
    try {
      await nft.connect();
      // show user address earlier
      setUpdateCnt((cnt) => cnt + 1);
      forceUpdate();
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }

  return (
    <Header>
      <div className="content" style={{ paddingRight: 20, paddingLeft: 20 }}>
        <a
          href="/"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'white',
            fontSize: 36,
            fontWeight: 'bold',
          }}>
          <div
            style={{
              width: 36,
              height: 36,
              backgroundColor: 'white',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img style={{ width: 24, height: 24 }} src="/favicon.png" />
          </div>
          &nbsp;&nbsp;
          <div
            onClick={() => AppActions.navigate('/')}
            style={{ display: 'inline-block' }}
            style={{}}>
            HIDEALL
          </div>
        </a>

        <div style={{ flex: 1 }} />
        <div
          style={{
            color: '#FFE5CA',
            fontWeight: 'bold',
            fontSize: 24,
            display: 'none',
          }}
          onClick={() => 0}>
          $HAAH
        </div>
        <div style={{ flex: 0.05 }} />
        {/*
        <a href="https://twitter.com/hideall_xyz" target="_blank">
          <img src="/images/twitter.png" style={{width: 36, height: 36}} />
        </a>
         */}
      </div>
    </Header>
  );
}

const Header = styled(BaseSection)`
  & > .content {
    height: 72px;
    background-color: #8294c4;

    & > h2 {
      color: white;
      font-size: 2.5rem;
      cursor: pointer;
    }

    & > .link-item {
      color: white;
      font-size: 0.8rem;
      margin: 0 20px;
    }
  }

  @media (max-width: 720px) {
    & .content {
      flex-direction: row;

      & > .link-item {
        display: none;
      }
    }
  }
`;

export default Navbar;
