import React from 'react';
import * as AppActions from '../../AppActions';
import { gTagEvent } from '../../Hooks/useGoogleAnalytics';
import Navbar from '../../Components/Navbar';
import { Button } from 'antd';
import { useOutlet } from 'reconnect.js';

function partialAddress(address, h = 8, t = 8) {
  const head = address.substring(0, h);
  const end = address.substring(address.length - t, address.length);
  return head + '...' + end;
}

const Landing = () => {
  const [countdown, setCountDown] = React.useState(10);
  const [dimension] = useOutlet('dimension');

  const isMobile = dimension.rwd === 'mobile';

  React.useEffect(() => {
    let id = setInterval(() => {
      if (countdown === 0) {
        AppActions.navigate('/txt-storage_v1');
      } else {
        setCountDown(countdown - 1);
      }
    }, 1000);
    return () => clearInterval(id);
  }, [countdown]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundImage: `url("/images/bg${isMobile ? '3' : ''}.png")`,
        height: '100vh',
      }}>
      <div style={{ width: '100%', maxWidth: 1024 }}>
        <Navbar />
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: 1024,

          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 20px',

          backgroundColor: 'rgba(255,255,255,0.3)',
        }}>
        <h1 style={{ alignSelf: 'center', marginTop: 50, color: '#3C486B' }}>
          All the privacy tools you need
        </h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: isMobile ? 'center' : 'space-between',
            alignItems: 'center',
            marginTop: 20,
            width: '100%',
          }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
            <div
              style={{
                width: isMobile ? 350 : 400,
                backgroundColor: '#BFCCB5',
                borderRadius: 30,
                paddingTop: 20,
                paddingBottom: 20,
                borderColor: 'white',
                borderWidth: 2,
                borderStyle: 'solid',

                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => AppActions.navigate('/txt-storage_v1')}>
              <h2
                style={{
                  margin: 0,
                  color: '#3C486B',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}>
                {!isMobile && <div>Encrypt & Decrypt Text on BlockChain</div>}
                {isMobile && (
                  <>
                    <div>Encrypt & Decrypt Text</div>
                    <div>on BlockChain</div>
                  </>
                )}
              </h2>

              <div
                style={{
                  fontWeight: 'bold',
                  color: '#F45050',
                  fontSize: 18,
                  marginTop: 10,
                }}>
                Launch APP (V1) ➠
              </div>
            </div>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
            <div
              style={{
                width: isMobile ? 350 : 400,
                backgroundColor: '#BFCCB5',
                borderRadius: 30,
                paddingTop: 20,
                paddingBottom: 20,
                borderColor: 'white',
                borderWidth: 2,
                borderStyle: 'solid',

                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => AppActions.navigate('/aes256')}>
              <h2
                style={{
                  margin: 0,
                  color: '#3C486B',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}>
                {!isMobile && <div>Encrypt & Decrypt Text Online</div>}
                {isMobile && (
                  <>
                    <div>Encrypt & Decrypt Text</div>
                    <div>Online</div>
                  </>
                )}
              </h2>

              <div
                style={{
                  fontWeight: 'bold',
                  color: '#F45050',
                  fontSize: 18,
                  marginTop: 10,
                }}>
                Enter ➠
              </div>
            </div>
          </div>
        </div>

        {/*
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: 150,
          }}>
          <Button disabled={true} style={{width: '22%', height: 50}}>
            Team
          </Button>
          <div style={{width: '4%'}}> </div>
          <Button
            style={{
              width: '22%',
              display: isMobile ? 'none' : null,
              height: 50,
            }}
            onClick={() => window.alert('Coming soon !')}>
            <div>Roadmap</div>
          </Button>
          <div style={{width: '4%'}}> </div>
          <Button
            style={{width: '22%', display: 'none', height: 50}}
            onClick={() => window.alert('Coming soon !')}>
            White Paper
          </Button>
          <div style={{width: '4%'}}> </div>
          <Button
            style={{width: '22%', display: 'none', height: 50}}
            onClick={() => window.alert('Coming soon !')}>
            $HAAH
          </Button>
        </div>
                 */}
        <div
          style={{
            marginTop: 50,
            color: '#8B1874',
            cursor: 'copy',
            backgroundColor: '#F0F0F0',
            padding: '5px 10px',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={async () => {
            await window.navigator.clipboard.writeText(
              '0x3229985dD941b8bdD7f7bd61EEde4cF8dCC4fa95',
            );
            gTagEvent('Click', {
              name: 'copy donate address (/)',
            });
            window.alert('Donate address copyied !');
          }}>
          <div style={{ display: 'inline-block', color: 'grey' }}>♥</div>
          &nbsp;&nbsp; Donate :{' '}
          {isMobile
            ? partialAddress('0x3229985dD941b8bdD7f7bd61EEde4cF8dCC4fa95')
            : '0x3229985dD941b8bdD7f7bd61EEde4cF8dCC4fa95'}{' '}
          &nbsp;&nbsp;
          <img src="/images/copy.png" style={{ width: 16, height: 16 }} />
        </div>
        {/*
        <a
          style={{
            marginTop: 20,
            color: '#8B1874',

            backgroundColor: '#F0F0F0',
            padding: '5px 10px',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          href="mailto:hideall.xyz@gmail.com">
          Contact US: hideall.xyz@gmail.com
        </a>
         */}
        <div
          style={{
            marginTop: 50,
            width: 350,
            backgroundColor: '#F45050',
            alignSelf: 'center',
            cursor: 'copy',
            color: 'white',
            padding: '5px 10px',
            borderRadius: 10,
            textAlign: 'center',
          }}>
          Jump to Encrypt/Decrypt tool in {countdown} seconds ...
        </div>
      </div>
    </div>
  );
};

export default Landing;
